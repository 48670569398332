.worklite_layout {
  --header-height: 48px;
}

.app_logo {
  text-align: center;
  height: var(--header-height);
  color: rgb(var(--arcoblue-6));
  line-height: 48px;
  margin: 0;
  cursor: pointer;
}

.app_header {
  height: var(--header-height);
  box-sizing: border-box;
  padding: 0 20px;
  border-bottom: 1px solid var(--color-neutral-2);
  background-color: var(--color-bg-2);
}

.app_nav_aria_label {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;
}

.qrcode_img {
  width: 160px;
  height: 160px;
  display: block;
  border-radius: 6px;
}

.app_nav_aria_label_icon {
  font-size: 20px;
  margin-left: 20px;
  color: var(--color-neutral-10) !important;
}

.app_nav_aria_label_icon.wechat {
  color: rgb(var(--green-6)) !important;
}

.app_content {
  background: var(--color-bg-1);
}

.app_footer {
  box-sizing: border-box;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--color-neutral-2);
  background-color: var(--color-bg-2);
}

.app_footer a {
  font-size: 12px;
  color: var(--color-neutral-6);
  text-decoration: none;
}