.tilt-box {
  width: 600px;
  height: 300px;
  border: 1px solid rgb(var(--arcoblue-2));
  border-radius: var(--border-radius-large);
  margin: 100px auto;
  perspective: 800px; /* 添加透视效果 */
  transition: transform 0.2s, box-shadow 0.2s; /* 动画效果 */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-color: var(--color-bg-1); */
  background-color: var(--color-neutral-1);
  position: relative; /* 允许子元素定位 */
  color: rgb(var(--arcoblue-6));
}

.content {
  font-size: 20px;
  font-weight: bold;
}